const roomProfitCalculateType = {
    "TYPES" : [
        {"Value" : 'COMMISSION', "Title" : 'KOMİSYONLU',},
        {"Value" : 'NET', "Title" : 'NET',}
    ],
    "COMTYPES" : [
        {"Value" : 'GRADUAL', "Title" : 'KADEMELİ',},
        {"Value" : 'CUMULATIVE', "Title" : 'KÜMÜLATİF',}
    ],
};
export default roomProfitCalculateType;
